.studentOverviewList {
    min-width: 100%;
}

/* .studentOverviewList .ms-DetailsList {
  overflow-x: hidden;
} */


.studentOverviewList .ms-DetailsHeader
{
    display: inline-flex;
    background-color: rgb(145, 145, 145);
    align-items: stretch;
}
.studentOverviewList .ms-DetailsHeader-cell
{
    color:#ffffff;
    border-right: 2px solid #ffffff;
}
.studentOverviewList .ms-DetailsHeader-cellSizer
{
    display: none;
    background: #ffffff;
    border-right: 2px solid red;
}

.studentOverviewList .ms-DetailsRow-cell {
    border-right: 2px solid #ffffff;
    padding: 0;
}

.studentOverviewList .ms-DetailsHeader-cellTitle {
    line-height: 1.2;
    display: flex;
  align-items: center;
  justify-content: center;
}

.studentOverviewList .ms-DetailsHeader-cell:last-child,
.studentOverviewList .ms-DetailsRow-cell:last-child {
  border-right: none;
}

.studentOverviewList .ms-DetailsHeader-cellTitle {
    white-space: pre-line;
    text-align: center;
}

.studentOverviewList .ms-DetailsHeader-cell {
    white-space: pre-line;
    text-align: center;
}

.cellRedBackground {
    background-color: red;
}

.multiline-column {
    white-space: normal;
    line-height: 1.5;
    height: 4.5em;
    overflow: hidden;
}

.cell-content {
  text-align: flex-start;
  height: 100%;
  line-height: 50px;
  padding-left: 2px;
}

.cell-content.trainingGroup {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 100%;
    margin: 0;
}
  
.studentOverviewList .ms-DetailsHeader-cell:hover,
.studentOverviewList .ms-DetailsRow:hover {
    background-color: transparent;
}

.studentOverviewList .ms-DetailsRow.is-selected:hover {
    background-color: transparent;
}

.changeGroupHoverButton {
    width: 15px !important; 
    font-size: 10px; 
}

/* CustomModal */
.modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px); /* Adjust the max-width as needed */
    max-height: calc(100% - 20px); /* Adjust the max-height as needed */
    overflow: auto; /* Enable scrolling within the modal if content exceeds dimensions */
}
  
.modal-content {
    width: 400px; /* Set the width of the modal */
    height: 300px; /* Set the height of the modal */
    border: 1px solid black; 
    box-shadow: none; /* Remove box shadow */
}
  
@media (max-width: 576px) {
    .modal-dialog {
        max-width: calc(100% - 10px); /* Responsive max-width for smaller screens */
        max-height: calc(100% - 10px); /* Responsive max-height for smaller screens */
    }

    .modal-content {
        width: 90vw; /* Responsive width for smaller screens */
        height: 90vh; /* Responsive height for smaller screens */
    }
}

.modal-footer {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
}

.closeX{
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0;
}

.logoImg {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px; 
    height: auto; /* Maintain aspect ratio */
    z-index: 1000; /* Ensure the image appears above other elements */
}


  
  


  
