.groupedListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.groupedListContainer .cellCompletion {
    padding-right: 500px;
}

.groupedListContainer .ms-DetailsRow-cell {
    padding-right: 50vw;
    color:black;
}
  