input.dialog-box {
    padding: 10px;
    width: 100%;
    border-radius: 25px;
    box-sizing: border-box;
    margin: auto auto 10px;
}

.dialog-header {
    color: black;
    font-weight: bold;
    font-size: 22px;
}

.dialog-label {
    margin: 20px 15px 15px;
}

.dialog-dropdown {
    width: 100%;
    max-width: 300px;
    margin: 0 auto 10px;
  }
  
  .dialog-dropdown .ms-Dropdown {
    width: 100%;
  }
  
  .dialog-dropdown .ms-Dropdown-title {
    border-radius: 25px;
    padding: 10px;
    height: 40px;
    line-height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #605e5c;
  }
  
  .dialog-dropdown .ms-Dropdown:focus::after {
    border: none;
    outline: none;
  }
  
  .dialog-dropdown .ms-Dropdown-caretDownWrapper {
    right: 10px;
    height: 40px;
    line-height: 40px;
  }
  
  .dialog-dropdown .ms-Dropdown-items {
    width: 100%;
    max-width: 300px;
  }
  
  .dialog-dropdown .ms-Dropdown-item {
    text-align: center;
    height: 36px;
    line-height: 36px;
  }

  .header-section {
    flex-shrink: 0;
    position: sticky;
    background-color: white;
    top: 0;
    padding-top: 20px;
    padding-bottom: 5px;
    max-width: 419px;
    width: 100%;
    z-index: 1;
  }

  .header-title{
    text-align: 'center' !important;
    font-weight: 450;
  }

  .contact-header {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 15px;
    position: relative;
  }
  
  .button-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    height: 60px;
  }
  
  .button-wrapper.contact-left {
    left: 0;
    top: 5px;
    
  }
  
  .button-wrapper.contact-close {
    right: 11px;
    top: 5px;
  }

  .button-wrapper.add-contact {
    right: 0;
    top: 20px;
  }
  .button-wrapper.back {
    left: 0;
    top: 20px;
  }
  
  .header-button {
    height: 30px !important;
    min-width: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
  }
  
  .button-icon {
    width: 30px;
    height: 30px;
    display: block;
  }
  
  .button-label {
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    white-space: nowrap;
  }