.rootdiv {
  height:100%;
  overflow-y: hidden;
  min-width: 960px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin:0;
  /*border:none;*/
}

/*
table {
  border-collapse: separate;
  border-spacing: 10px;
}

table td {
  padding: 5px;
}
*/

p {
  margin-top: 0.30em ;
  margin-bottom: 0.30em ;
}

/*to get 100%, we need to include div into the list
with html, and body, but this disables the editor,
// like the editor is in a loop of layout/??
//maybe we get rid of the editor
// and just use plain text field???
*/
html {
  height: 100%;
  /*width: 90%;*/

  margin: auto;
  /*position:fixed;*/
}

body {
  height: 100%;
  min-width:900px;
  /*width: 90%;*/

  margin: auto;
  /*position:fixed;*/
}

.test {
  min-width: 1000px;
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  html, body {
    min-width:360px;
    text-size-adjust: none;
  }
}

h3 {
  margin-top: 5px;
  margin-left: 15px;
  /*margin-right: 15px;*/
}
div {
  height: auto; /*100%; /*400px;*/
  box-sizing: border-box;
  margin:0;
  padding: 0;
  margin-left:0;
  padding-left:0;
  scrollbar-width: thin;
  border:none;
}

html, body {
  overflow: hidden;
}

.BookList {
  width: 210px;
  height: 100%; /*400px;*/
  /*overflow-y: auto; /*height: 50px;*/
  overflow-x:hidden;
  border: 1px solid black;
}

.TableOfContent {
  width: 380px; /*490px; shrink by 110px to give to the content editor*/
  height: 100%; /*400px;*/
  overflow-y: hidden; /*height: 50px;*/
  overflow-x:hidden;
  border: 1px solid #808080;
  border-top-right-radius:29px;
  border-top-left-radius:29px;
}

.ContentEditor {
  width: calc(100vw - 100px - 280px - 400px);
  /*height: 100%; /*400px;*/
  overflow-y: hidden;
  overflow-x:hidden;
  border: 1px solid black;
  border-top-right-radius:29px;
  border-top-left-radius:29px;
}

/*
.WritingTableOfContent {
  width: 380px;
  overflow-y: auto;
  overflow-x:hidden;
  border: 1px solid black;
}
*/

.BookContent {
  width: 400px;
  height: 100%; /*400px;*/
  border:none;
  overflow-y: hidden;
}

.ChatContent {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
  overflow-y: hidden;
}
/*
  border-color: black;
1px solid rgb(138, 136, 134)
1px solid rgb(96, 94, 92)
*/
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  background-color: white;
  overflow:hidden;
  cursor: pointer;
  border:1px solid rgb(96, 94, 92);
  margin-top:5px;
}

.jsonEditor {
  /*width: calc(100vw - 210px - 280px - 400px);*/
  width: calc(100vw - 100px - 280px - 400px);
  height: 100%; /*400px;*/
  overflow-y: hidden;
  overflow-x:hidden;
  border: 1px solid #808080;
  border-top-right-radius:29px;
  border-top-left-radius:29px;
}

.fellowshipNoteEditor {
  /*width: calc(100vw - 210px - 280px - 400px);*/
  /* width: calc(99vw - 100px - 280px - 400px); */
  height: 100%; /*400px;*/
  overflow-y: hidden;
  overflow-x:hidden;
  border: none;
}

.fellowshipNotePell {
  border: 1px solid !important;
}
.BookCover {
  width: 180px;
  margin-left:5px;
  margin-top:10px;
  border:1px solid black;
}

.footer{
  position: relative;
  clear: both;
  width: 100%;
  margin-top: 20%;
  height: 2.5rem;
  /*padding-left: 5%;*/
  /*padding-right:5%;*/
}

.footer ul {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.footer li {
  float: left;
  margin-left: 3%; /*50px;*/
  text-wrap: nowrap;
}

.FullscreenBookCover {
  position:absolute;
  top:5px;
  left:5px;
  width: 140px;
  height:140px;
  max-width: 140px;
  max-height: 140px;
  min-width: 140px;
  min-height: 140px;
  background: azure;
}

.bookNameUnderImage {
  margin-left:5px;
  margin-right:5px;
  /*z-index: -1;*/
  position: absolute;
  top: 5px;
  left: 5px;
  height:140px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FullscreenBookCoverDiv {
  /*overflow:hidden;*/
  position:relative;
  width: 152px;
  height:152px;
  margin-left:0px;
  margin-right:5%; /*37px;*/
  margin-top:20px;
  border:1px solid #D3D3D3;
  float:left;
  word-break:break-all;
}

/*  // this is confusing as click on the drop down menu, didn't stop the
    // the click even on the book cover image, that ends up into the
    // book authoring page...
    // works fine with ev.stoppropagation
*/
.dropdown-content-4-book {
  display: none;
  position: absolute;
  left:20px;
  top:110px;
  background-color: #f1f1f1;
  z-index: 1;
  float:left;
}

.FullscreenBookCoverDiv:hover .dropdown-content-4-book {display: block; }
/*.FullscreenBookCoverDiv:hover img {background-color: #3e8e41;}*/
/*.FullscreenBookCoverDiv:hover h2 {background-color: #3e8e41;} */
.FullscreenBookCoverDiv:hover {border-color: #ff0000;}
/**/

.ColumnHead {
  text-align:center;
  margin: 0;
  background-color: #808080;
  color:white;
  font-weight:bold;
  line-height:29px;
  border-top-right-radius:29px;
  border-top-left-radius:29px;
}

.ChapterButtonList {
  height: auto;
  border: none;
  margin: 2px;
  padding-bottom:20px;
}

.ChapterButton {
  text-align: left;
  width:calc(100% - 8px);
  margin-left:4px;
  padding:4px;
  margin-top:5px;
  border:none;
  border-radius:25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ChapterButton span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: sub;
  display: inline-block;
}

.UpdateChapterButton {
  text-align: left;
  width: 88%; /*257px;*/
  height:30px;
  /*margin-left:2px;*/
  padding:4px;
  margin-top:5px;
  border:none;
  border-radius:25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.UpdateChapterButton span {
  float:right;
}

/* // this overrides the searchbox behavior.
.ms-SearchBox .ms-SearchBox-iconContainer {
  width: 30px;
}
*/

tr:nth-child(even) {
  background: #EEEEEE
}

.ContentV {
  width:100%;
  border:none;
  overflow-y:auto;
  overflow-x:hidden;
}

.ContentV3 {
  margin: 0 15px 0 3px;
  padding: 0;
  border:none;
  overflow-y:auto;
  width: calc(100% - 3px);
}

.HomeMatrixBooks {
  width:100%;
  margin: auto;
  padding-left: 5%;
  /*padding-right: 5%;*/
  border:none;
  overflow-y:auto;
  overflow-x:hidden;
}

.PellContent {
  height: auto; /*60px; /*-- auto doesn't work, it increases the height===*/
  overflow-y: auto;
  padding:5px;
  outline:0;
  box-sizing: border-box;
}
.PellButton {
  width:27.5px;
  border:none;
  height:30px;
}


.bookCategoryMark {
  background:black;
  height:1em;
  width:1em;
  border:none;
  margin-right: 5px;
}


.inActiveSvgText {
  width:0;
  margin:0;
}

.inActiveSvgText .svgClass {
  stroke:rgb(163,163,163);
  fill: transparent;
}

.inActiveSvgText .textClass {
  fill: rgb(163,163,163);
  font-size: 20;
  font-weight: bold;
}
.activeSvgText {
  width:0;
  margin:0;
}

.activeSvgText .svgClass {
  stroke:black;
  fill: black;
}

.activeSvgText .textClass {
  fill: rgb(255,255,255);
  font-size: 24;
  font-weight: bold;
}

.studySessionsNav {
  margin-left: 10px;
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}

.dropdown4SessionNav {
  display: none;
  position: absolute;
  top:40px;
  background-color: #f1f1f1;
  z-index: 1;
}

.studySessionsNav:hover .dropdown4SessionNav {display: block;}
.activeSvgText:hover {stroke: #3e8e41;}
.inActiveSvgText:hover {stroke: #3e8e41;}

.AddSessionSvgText {
  width:0;
  margin:0;
}

.AddSessionSvgText .svgClass {
  stroke:rgb(163,163,163);
  fill: transparent;
}

.AddSessionSvgText .textClass {
  fill: rgb(163,163,163);
  font-size: 20;
  font-weight: bold;
}

.iconNavCmdClass {
  font-size: 15;
  margin: 5;
}


a:link {
  color: black;
  background-color: transparent;
  margin-right: 1%; /*2.3em;*/
  text-decoration: none;
}

/*
a:visited {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: black;
  background-color: yellow;
  text-decoration: underline;
}

*/
/*
  font-size: 20px;
*/
.active {
  font-size: 20px;
  text-underline-position: under;
  text-decoration: underline solid #909090 !important;
  text-decoration-thickness: 10px !important;
}

.header-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
  line-height: 50px;
}

.nav-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerLinks {
  width: 120px;
  color: #505050;
  display: block;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-section {
  width: 240px;
}

.search-section.hidden {
  visibility: hidden;
}

.auth-section {
  margin-left: auto;
}

@media screen and (max-width: 640px) {
  .header-container{
    padding-left: 0;
  }

  .nav-section {
    display: contents;
  }

  .headerLinks {
    width: 45px;
    font-size: 14px;
  }

  .search-section {
    width: 80px;
  }
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 100;
  left: 0;
  line-height:initial;
}

.box {
  position: relative;
  width: 50%;
  height: auto;
  max-height: 70vh;
  margin: calc(100vh - 85vh - 20px) auto 0;
  background: #fff;
  border-radius: 25px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  font-weight: bold;
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(25% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid white;
  font-size: 20px;
}

.iDigestLogo {
  height: 32px;
  /* margin-right: 30px; */
  vertical-align: middle;
  /*padding-top:10px;*/
}

.centeriDigestLogo {
  /* margin-right: 30px; */
  vertical-align: middle;
  padding-top:10px;

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.bookCategoryHeader {
  width: 100%;
  margin-top: 15px;
  margin-left: 0px;
  font-weight: bold
}

.headerDiv {
  height: auto; /*50px;*/
  padding-left: 3%;
  padding-right:3%;
  width: 100%;
  border-bottom: solid #D3D3D3 1px;
  box-sizing: border-box;
}

/*
  position: relative;
  clear: both;
  width: 100%;
*/
.aboutUs {
  padding-left:5%;
  padding-right:5%;
  overflow-y: auto; /*height: 50px;*/
  height: calc(100vh - 52px);
  /*scroll-margin-top: 10px;*/
  scroll-padding-top: 15px;
}

  /* The device with borders */
.smartphone {
    position: relative;
    /*position: absolute;*/
    top: 0px;
    width: 394px;
    /*height: 100%;*/
    margin: 0;
    margin-left:3px;
    border: 1px solid #808080;
    /*border-top-width: 30px;*/
    /*border-bottom-width: 30px;*/
    border-top-right-radius:29px;
    border-top-left-radius:29px;
    /*border-radius: 29px;*/
    /*overflow-y:hidden;
    box-sizing: border-box;*/
}

.AuthorClassHeadings {
  text-align: center;
  margin-left:0px;
  margin-top: 5px;
  /*display: contents;*/
}

.AuthorClassDivs {
  width: auto;
  margin-left: 7px;
  padding-right: 7px;
  position: relative;
  text-align: center;
}

.PickGroupDialogue {
  min-width: 650px;
}

.PickGroup {
  margin-left: 60px; /*15px;*/
  min-width: 600px; /*350px;*/
  display: inline;
  margin: 0px;
  text-align: center;
}

/* Default styling for dialog */
.PickMeetingDialogue {
  height: 80vh;
  width: 100%;
  max-width: 500px;
  min-width: 350px;
  border-radius: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Responsive adjustment for smaller screens */
@media (max-width: 500px) {
  .PickMeetingDialogue {
    width: 100vw;
    overflow-y: auto;
    /* max-width: none;  */
    border-radius: 20;
  }
}

@media (max-height: 500px) {
  .PickMeetingDialogue {
    height: 100vh;
    max-height: none; 
    border-radius: 0;
  }
}

.PickMeeting {
  max-width: 419px; /*350px;*/
  display: block;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

/*.FooterLink {*/
/*.footer a {*/
.FooterLink {
  /*marginRight: 50,*/
  color: black;
  text-underline-position: auto;
  font-size: initial;
}

.closeButton {
  border: 1px solid;
  left: 316px;
  margin-left:10px;
  margin-right: 10px;
  margin-top: 2px;
  position: fixed;
  background-color: #FFFF00;
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .rootdiv {
    height:100%;
    overflow-y: hidden;
    min-width: 360px;
  }

  .PickGroupDialogue {
    min-width:350px;
  }
  .PickGroup {
    min-width: 350px;
  }
  .PickMeetingDialogue {
    width: 100vw;
    /* max-height: -webkit-fill-available; */
    /* max-width: none; */
    /* border-radius: 20; */
  }
  .PickMeeting {
    min-width: 250px;
  }
  .jsonEditor {
    visibility: hidden;
    width: 0;
  }
  .ColumnHead {
    border-top-right-radius:0;
    border-top-left-radius:0;
  }
  
  .TableOfContent {
    width: 100%;
    z-index: 0;
    border-top-right-radius:0;
    border-top-left-radius:0;
  }
  .BookContent {
    width:100%;
    position:absolute;
    left:0;
    background: white;
    z-index: 1;
    border-top-right-radius:0;
    border-top-left-radius:0;
  }
  .BibleNavigation {
    width:100%;
    background: white;
    z-index: 2;
  }
  .smartphone {
    width: 100%;
    margin-left:0px;
    margin-right:0px;
    margin: 0;
  }
  .iDigestLogo {
    position:relative;
    left: 0px;
    float:left;
    /*
    left:50%;
    transform:translate(-50%);
    display:block;*/
  }

  a:link {
    margin-right: 3px;
  }

  .headerDiv {
    padding-left: 4px;
    padding-right: 4px;
    /*width: 90%;*/
  }
}

/* when in only portrait mode for a phone
@media screen and (max-device-width: 450px) {
}

we can condition to 152px * 3  == 456 === anything less than 456 will split into two columns only,
and we work on the two column scenario.
<<<the screen is likely around 450****
.FullscreenBookCoverDiv {
  position:relative;
  width: 152px;
  height:152px;
  margin-left:0px;
  margin-right:5%;
  margin-top:20px;
  border:1px solid #D3D3D3;
  float:left;
  word-break:break-all;
}

.HomeMatrixBooks {
  width:100%;
  margin: auto;
  padding-left: 5%;
  padding-right: 5%;
  border:none;
  overflow-y:auto;
  overflow-x:hidden;
}


*/
