.avatar-container {
    display: flex;
    align-items: center; /* Align items vertically centered */
    justify-content: center; /* Align items horizontally centered */
    border-radius: 50%;
    overflow: hidden;
    background-color: #e0e0e0; /* Optional: Add background color for non-avatar icons */
  }

  .add-member-btn {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Same size as the avatar */
    height: 40px; /* Same size as the avatar */
    border: 1px solid #ccc;
    background-color: #e0e0e0;
    border-radius: 50%; /* Make it round like the avatar container */
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
  }
  
  .member-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust as needed for spacing */
    padding: 10px;
    border: 1px solid rgb(96, 94, 92);
  }

  .selected-user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .avatar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 5px;
  }
  
  .avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px; /* Size of the avatar */
    margin-right: 10px;
  }
  
  
  .avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
  }
  
  .avatar-icon {
    font-size: 24px; /* Adjust icon size as needed */
    color: #555; /* Adjust icon color as needed */
  }

  .avatar-name {
    margin-top: 5px;
    text-align: center; /* Add space between avatar and name */
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 3rem;
    color: #000; /* Adjust color as needed */
  }
  
  .close-button:hover {
    color: #808080; /* Change color on hover */
  }

  .back-button {
    position: absolute;
    top: -5px;
    left: 0px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 3rem;
    color: #000; /* Default color */
  }
  
  .back-button:hover {
    color: #808080; /* Gray color on hover */
  }
  
  .checklist {
    display: flex;
    flex-direction: column;
  }
  
  .checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .checklist-item input[type='checkbox'] {
    margin-right: 10px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto; /* Allow vertical scrolling */
  }

  .modal-content {
    max-height: calc(100vh - 200px); /* Adjust as needed */
    overflow-y: auto; /* Enable vertical scrolling for content */
    background-color: #fff; /* Set background color to white */
  }

  .selected-users-box {
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in width */
    padding: 8px;
    font-size: 16px;
    border: 1px solid rgb(96, 94, 92);
    border-radius: 4px;
    resize: none;
    overflow: hidden;
  }

  .checkbox-content {
    display: flex;
    align-items: center;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
  }
  
  
  
  


  