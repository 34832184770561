.container {
  display: flex;
  /*flex-wrap: wrap;*/
  flex-direction: row;
  padding: 2%;
  justify-content: flex-start;
}

.leftPanel {
  flex: 0 0 25%;
  min-width: 240px;
  width:30%;
  box-sizing: border-box;
  height: 90vh;
  overflow-y: auto;
}

.rightPanel {
  float: right;
  flex: 0 0 73%;
  width:70%;
  box-sizing: border-box;
  padding-left: 2%;
  height: 90vh;
  overflow-y: auto;
}

.header-with-back-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-with-back-button h2 {
  flex-grow: 1;
}

.export-button {
  margin-left: auto;
}

.verticalLine {
  background-color: black;
  width: 0.1%;
  height: 100%;
  position: absolute;
  left: 30%;
  margin-left: -3%;
  position: fixed;
}

  .session {
    margin-bottom: 20px;
  }

  .session button {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }

/*   button {
    font-size: 16px;
    padding: 10px 20px;
    margin: 5px 0;
    border: 1px solid gray;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f8f8f8;
  
  } */

  .class-management-button {
    font-size: 16px;
    padding: 10px 20px;
    margin: 5px 0;
    border: 1px solid gray;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f8f8f8;
  
  }
  
  .class-management-button button:hover {
    background-color: #e0e0e0;
  }

  .courses-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 20px;
  }
  
  .courses-container button {
    margin-right: 10px;
  }
  
  .ms-Button {
    border-radius: 20px;
  }
  
  .customDropdown {
    width: 80px;
    margin-left: 10px;
    margin-right:7px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .button-container .ms-Button,
.add-training-button {
  width: 70%;
  max-width: 100%;
  margin-left: 7px;
  height: 50px;
  border-radius: 10px 10px 10px 10px;
  background-color: #f5f5f5;
}
.unique-add-button {
  background-color: #ffffff;
}


.add-group-button{
  width: 30%;
  max-width: 100%;
  background-color: white;
  margin-left: 2%;
}
.group-button {
  width: 30%;
  max-width: 100%;
  background-color: #e0e0e0;
  margin-left: 2%;
}

.dialog-group-Button {
  width: 150%;
  min-width: 200px;
  margin-top: auto;
  display: block;
  background-color: white;
  margin-left: 0;
}

.dialog-group-container {
  width: 100%;
  min-width: 600px;
}

.square-button {
  width: 10%;
  height: 100px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.course-dialog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10%;
  gap: 5%;
  border-radius: 50px;
}

.dialog-close-button-container {
  position: absolute;
  top: -30px;
  right: -20px;
  cursor: pointer;
}

.dialog-close-button {
  margin: 0;
  padding: 0;
  min-width: 30px;
  background-color: white;
  border: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container .save-rename-button {
  width: 20%;
  margin-left: 10px;
}

.registration-link-container {
  margin-left: 20px;
}
.all-group-link-container {
  margin-left: 20px;
}

.button-selected, .button-selected:hover {
  background-color: #605f5f !important; 
  color: #ffffff;
}

.custom-link {
  color: blue;
  text-decoration: underline;
}

.custom-link:hover {
  color: darkblue;
}

.custom-link:active {
  color: navy;
}

.FullscreenBookCover {

  top:5px;
  left:5px;
  width: 140px;
  height:140px;
  max-width: 140px;
  max-height: 140px;
  min-width: 140px;
  min-height: 140px;
  background: #FFFFFF;
}

.FullscreenBookCoverDiv {
  /*overflow:hidden;*/
  position:relative;
  width: 152px;
  height:152px;
  margin-left:0px;
  margin-right:5%; /*37px;*/
  margin-top:20px;
  border:1px solid #D3D3D3;
  float:left;
  word-break:break-all;
}

/* .FullscreenBookCoverDiv:hover .dropdown-content-4-book {display: block; } */
/*.FullscreenBookCoverDiv:hover img {background-color: #3e8e41;}*/
/*.FullscreenBookCoverDiv:hover h2 {background-color: #3e8e41;} */
/* .FullscreenBookCoverDiv:hover {border-color: #ff0000;} */
/**/

.add-course-button {
  top:10px;

  position:relative;
  width: 152px;
  height:152px;
  margin-left:0px;
  margin-right:5%;
  margin-top:20px;
  max-width: 152px;
  max-height: 152px;
  min-width: 152px;
  min-height: 152px;
  background: #FFFFFF;
  float:left;
  word-break:break-all;
  border-radius: 0;
}
.course-item {
  width: 152px;
  height: 152px;
  margin: 0 5% 20px 0;
  border: 1px solid #c1c1c1;
  float: left;
  word-break: break-all;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent
}

.qr-code-container {
  margin-top: 10px;
  margin-left: 2vh;
}

.class-management-button,
.add-training-button,
.group-button,
.save-rename-button,
.add-group-button {
  border: 1px solid #c1c1c1;
}

.course-hover-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.FullscreenBookCoverDiv:hover .course-hover-buttons {
  display: block;
}

.delete-button {
  margin-top: 30px !important;
}
.detail-button,
.delete-button {
width: 100px;
max-width: 100px; 
min-width: 100%;
height: 40px;
}


.detail-button:hover,
.delete-button:hover {
  background-color: #605f5f;
  color: white;
}

.added-all-courses {
margin-left: 110px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.added-group {
  background-color: #C1C1C1 !important;
  color: #7E7E7E !important;
}

.course-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.course-button {
  flex-grow: 1;
  margin-right: 10px;
}

.remove-group-button {
  margin-left: 10px;
}

.unique-dialog-footer {
  display: flex;
  justify-content: center;
}

.custom-dialog .ms-Dialog-header button {
  top: 10px !important;
}

.course-name-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #dcdcdc;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  word-break: break-word;
}

.mobile-message {
  width:100%;
  margin: auto;
  padding-left: 5%;
  /*padding-right: 5%;*/
  border:none;
  overflow-y:auto;
  overflow-x:hidden;
}


@media screen and (orientation: portrait) {
  .container {
    justify-content: center;
  }

  
  .leftPanel,
  .rightPanel {
    padding: 0;
    flex-basis: auto;
    min-width: 80%;
    width: 100% ;
  }
  .rightPanel {
    flex: 0 0 60%;
  }
  .verticalLine {
    display: none;
  }

  .button-container .ms-Button,
  .add-group-button,
  .group-button {
    width: 100%;
  }
  
  .add-training-button{
    width: 95%;
  }

  .square-button,
  .course-item {
    width: 100%;
    height: 80px;
  }

  .course-dialog-container,
  .dialog-close-button-container,
  .button-container .save-rename-button {
    width: 50%;
    margin: 0 auto;
    padding: 5%;
    overflow-y: auto;
    max-height: 60vh;
  }
  .dialog-close-button-container {
    top: -15%;
    right: -25%;
  }
}