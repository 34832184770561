.panel-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: #4a4a4a; /* Dark gray background */
  min-height: 49px;
  justify-content: flex-end; /* Keep action buttons at the right side*/
}

.panel-header .header-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0 8px;
  z-index: 1;
}

.panel-header .header-actions {
  display: flex;
  gap: 15px;
  margin-right: 15px;
  margin-top: 2px;
}

.panel-header .button-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.panel-header .button-with-label span {
  font-size: 9px;
}

.searchbox-wrapper {
  min-width: 380px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.grouplist-wrapper {
  min-width: 380px;
}

.left-panel {
  flex-direction: column;
  margin-left: 10px;
}
.middle-panel {
  flex: 0 0 500px;
  flex-direction: column;
  background-color: #f5f5f5;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.right-panel {
  flex: 1;
  min-width: 300px;
  margin-right: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.right-panel .fellowshipNoteEditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* Pell editor toolbar customization */
.right-panel .pell-content {
  padding: 10px;
  flex: 1;
  overflow-y: auto;
}

.right-panel .pell-actionbar {
  background-color: white;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  border-bottom: 1px solid #e0e0e0;
}

.group-mgmt-container {
  display: flex;
  gap: 10px;
  /* padding: 10px; */
  height: 100%;
}

@media screen and (max-width: 640px) {
  .middle-panel {
    flex: 1;  
    width: 100%;
    min-width: 100%;
    border-left: none;
    border-right: none;
  }

  .right-panel {
    display: none;
  }
}