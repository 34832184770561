body {
  background-color: white;
  overflow: auto;
}

.orgGroupDiv {
  position: relative;
  float: left;
  left: 5%;
  /*top: 20px;*/
  width: 40%;
  height: 100%;
  /*z-index: 1010101010*/
  margin-right: 10%;
}

.orgGroupButton {
  /* border: 1px solid grey; */
  border-radius: 1px;
  padding: 10px;
  margin: 2px 0;
  width: 100%;
}

#attendance {
  table-layout: auto;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  overflow-x: auto;
  overflow-y: auto;
}

#GroupImage {
  height: 25px;
  width: 25px;
  position: absolute;
  left: 5px;
}

#OrgImage {
  height: 25px;
  width: 25px;
  position: absolute;
  left: 10px;
}

/*
  float:left;
  position: absolute;
  left: 5;
*/

.fixed {
  position: absolute;
  left: auto;
  top: auto;
}

#attendance td, #attendance th {
  border: 1px solid white;
    /*padding: 8px;*/
  text-align: left;
}

#attendance td {
  /*font-weight: bold; */
  padding-top:5.5px;
  padding-bottom:5.5px;
}

#attendance td:first-child {
  /*font-weight: bold;*/
  background-color: white;
  position: sticky;
  left: 0;
}

#attendance th:first-child {
  /*font-weight: bold;*/
  background-color: white;
  position: sticky;
  left: 0;
}

#attendance tr:nth-child(even){background-color: #D8D8D8;}
#attendance tr:nth-child(odd){background-color: #f2f2f2;}

#attendance tr:hover {background-color: #BEBEBE;}

#attendance th {
  padding-left: 3px;
  padding-right: 3px;
  background-color: #BEBEBE;
  color: white;
}

.listDiv {
  margin-top: 20px;
}

.vl {
  border-right: 1px solid #D3D3D3;
  height: 800px;
  position: absolute;
  /*left: 50%;*/
  margin-top: 65px;
  margin-left: 0;
  top: 20px;
  width:50%;
  min-width:450px;
  z-index:-1; /*this otherwise covers the left org div, and prevents clicks*/
}

/* overflow: auto;
  height: 700px;
*/

.responsiveDiv {
    /*height: 700px; */
  width: auto;
  /*margin-top:20px;*/
  display:flex;
  overflow-y: auto;
  height: 100%;
}

.allMemberDiv {
  height: 700px;
  width: auto;
  overflow: auto;
}

.table {
  float: left;
  border-left-color: grey;
  overflow-y: auto;
  height: 100%;
}

.selected {
  float:right
}

.buttonColor:nth-child button {
  background-color: white;
  border-radius: 25px
}

/* .buttonColor:nth-child(even) button {
  background-color: white;
  border-radius: 25px
} */

.actionButton {
  margin-top: 20px;
  background-color: black;
  color: white;
  border-radius: 25px;
  margin-left:20px
}

.buttonFellowship {
  border: 1px solid grey;
  border-radius: 25px;
  padding-right: 4px;
  padding-left: 20px;
  width: 100%;
}

.buttonFellowship:hover {
  background-color: #f1f1f1;
}

.groupSymbol {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-right: 15px;
}

.ulGroups {
  margin-bottom: 4px;
  margin-right: 5%;
  margin-left: 5%;
  padding: 0;
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .buttonFellowship {
    padding-right: 4px;
    padding-left: 20px;
    box-sizing: border-box;
    width: -webkit-fill-available;

    input, select, textarea, div, span {
      font-size: 16px;
    }
  }
}

.fellowshipGroup {
  border: none;
  border-top-right-radius: 0;
  overflow-y: auto;
  /*border-right: 1px solid;*/
}

.fellowshipActionDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 1.1em;
  padding-right: 1.1em;
}

.fellowshipAction {
  background-color: black;
  color: white;
  width: 6em;
  border-radius: 25px;
  /*margin-left: 1.1em;*/
  padding: 0.3em;
}

.tabContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
  position: relative;
}

.plusButton {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Make it a perfect circle */
  background-color: white;
  color: black;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black; /* 2px solid black border */
  cursor: pointer;
  margin-left: 10px;
}

.tos-links a {
  background-color: transparent;
  margin: 0;
  color: #36c;
  cursor: pointer;
}
