.classregistration * {
  box-sizing: border-box;
}

.classregistration {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: #f0f0f0;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
  max-width: 600px;
  margin: auto;
}

.form-container {

  padding: 30px;
  border-radius: 8px;

  width: 600px;
  max-width: 100%;
  margin: auto;
}
.confirmation-container {
  background-color: #ffffff00;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  overflow-y: auto;
  text-align: center;
  flex-direction: column;

}

.registration-success-container
{
  background-color: #fff;
  padding: 30px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  overflow-y: auto;
  max-height: 95vh;
}

.input-container {
  margin-bottom: 40px;
}

.input-container label {
  display: block;
  margin-bottom: 5px;
  /* font-weight: bold; */
  font-size: 20px;
  text-align: left;
  text-align: center;
  font-size: 1.4em;
}

.input-container input,
.input-container select,
.input-container textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid black;
  border-radius: 20px;
  text-align: center;
  background-color: #ffffff;
}
.registration-success-container .input-container input,
.registration-success-container .input-container select,
.registration-success-container .input-container textarea {
  border: 1px solid rgba(254, 254, 254, 0);
}
.input-container input::placeholder,
.input-container select::placeholder,
.input-container textarea::placeholder {
  color: gray; /* 淡色 */
  opacity: 1; /* 防止不同浏览器的不一致性 */
}

.input-container .textarea-custom.textarea-experience {
  height: 100px;
  text-align: left;
}

.form-footer {
  text-align: center;
  color: #007bff;
}
.form-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 20px 30px;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3em;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 1000px;
  width: 90%;
  text-align: center;
  border-radius: 25px;
  position: relative;
}

.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 30px;
}

.fluentInput {
  width: 40%;
  margin: auto;
  max-width: 500px;

  background-color:#ffffff;
}

@media (max-width: 768px) {
  .form-container,
  .modal-content {
    width: 100%;
    max-width: none;
    padding: 20px;
  }

  .page-container {
    padding: 10px;
  }
}

.intro-page-content p,
.confirmation-container p,
.success-container p {
  text-align: center;
  margin: 20px;
  font-size: 1.3em;
}

.modal-content {
  text-align: center;
}


.registration-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  overflow-y: scroll;
}

.close-button {
  position: absolute;
  top: -70px;
  right: -55px;
  width: 55px;
  height: 55px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  font-size: 70px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.close-button:focus {
  outline: none;
}

.course-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.registration-title {
  font-size: 1em;
  color: red;
  margin-bottom: 20px;
}

.password-reset-instruction {
  font-size: 0.7em;
  color: #6c757d;
  margin-top: 5px;
}


.step-two-input select,
.step-two-input textarea,
.step-two-input input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  text-align: center;
  background-color: #f0f0f0;
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4em;
  color:black;
}


.step-two-input select option {
  color: black;
}

.acknowledgment-list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.acknowledgment-list p {
  white-space: nowrap;
  overflow: auto;
}
.acknowledgment-options {
  display: flex;
  justify-content: center;
}


.step-two-title {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 50px;
}

label[for="acknowledgmentNo"] {
  margin-left: -100px;
}
label[for="acknowledgmentYes"] {
  margin-left: -100px;
}

.radio-button-large {
  transform: scale(1.5);

}

/* .select-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */

.select-container {
  position: relative;
}

/* .select-container:after {
  content: "";
  position: absolute;
  right: 1px;
  transform: translateY(-50%);
  width: 460px;
  top:70%;
  height: 10px;
  background-image: url('./rightArrow.png');
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
} */



.classRegDropdown-title {
  width: 100%;
  height: 100px;
  line-height: 50px;
  border-radius: 20px;
  border: 1px solid #a6a6a6;
  background-color: #f3f3f3;
  margin: auto;
}

.classRegDropdown-items,
.classRegDropdown-callout {
  border-radius: 25px;
}

.classRegDropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 25px;
}

.classRegDropdown-dropdown {
  width: 50%;
  margin-top: 10px;
  border-radius: 25px;
}

.error-message {
  color: red;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-size: 2em;
}

.missing-filed-message {
  color: red;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 5px;
}


@media screen and (orientation: portrait) {
  .acknowledgment-list p {
    white-space: normal;
    overflow-x: visible;
    word-wrap: break-word;
  }
}

@media (max-width: 768px) {
  .fluentInput {
    width: 100%;
  }

  .fluentInput input,
  .fluentInput select,
  .fluentInput textarea {
    font-size: 1.2em;
    padding: 15px;
  }

  .close-button {
    top: 0px;
    right: 15px;
  }

  .registration-success-container {
    min-height: 80vh;
    max-height: 80vh;
  }
}

.input-container {
  margin: 15px 0; /* Reduced the margin for smaller space */
}

#contactEmail, #contactIntroduction {
  padding: 10px;
  width: 80%; /* Adjusted to match the design reference */
  border-radius: 25px;
  margin-top: 5px; /* Reduced margin */
}
